<template>
  <div class="home">
    <v-container class="mt-5">
      <v-row>

        <img class="mt-5 ml-2" :src="require('@/assets/iconologo.svg')" style="width: 100px;">
      </v-row>
      <v-row class="mt-10" v-show="!formSent">
        <v-col md="12">
          <h2 class="mb-5">Inscreva-se para a turma de Introdução à Programação - IP01</h2>
          <v-text-field label="Nome" v-model="interestedPerson.name"></v-text-field>
          <v-text-field type="email" label="E-mail" v-model="interestedPerson.email"></v-text-field>
          <v-text-field label="Whatsapp (DDD+Telefone)" type="number" v-model="interestedPerson.phone"></v-text-field>
          <small>Ao prosseguir você concorda em receber comunicados da Arca por e-mail e telefone.</small>
          <br />
          <v-btn color="#001866" @click="submitInterest()" class="text-white mt-2">
            Confirmar
          </v-btn>
          <br />
          <br />
          <div v-show="options.showSocials">
            <v-icon icon="mdi-instagram"></v-icon>
            <v-icon icon="mdi-linkedin"></v-icon>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-10" v-if="formSent">
        <v-col md="12">
          <h2 class="mb-5">😍 Estamos felizes com sue interesse, {{ interestedPerson.name.split(' ')[0] }}!</h2>
          <p>O Instituto Arca quer ser uma ferramenta de transformação de realidades através da tecnologia, e talvez você
            seja o próximo #Arcano a ser transformado. Para isso é importante ficar atento aos próximos passos:</p>
          <br />
          <p>
            <strong>🛣️ 1. Trilha do Conhecimento <br /></strong>
            Para que todos os arcanos estejam na mesma página, preparamos uma trilha de conhecimento onde você terá a
            acesso a diversos conceitos, através de artigos e links que juntamos para você no <a
              href="http://bit.ly/3P9NFIc">neste link (http://bit.ly/3P9NFIc)</a>.
            <br />
            Daqui a 7 dias corridos, contados a partir de <u>hoje</u>, você receberá um link para agendar sua prova de
            nivelamento, onde você será testado sobre os conhecimentos apresentados a você na Trilha.
            <br />
            Não se preocupa, a prova é apenas para garantir que estamos todos na mesma página, e se você tiver qualquer
            dificuldade durante sua jornada na trilha, estaremos mais do que prontos para te ajudar em nosso <a
              href="https://discord.gg/UG2vvuhK">Discord</a>.
          </p>
          <br />
          <p>
            <strong>📐 2. Prova de Nivelamento <br /></strong>
            Como dito anteriormente, daqui a 7 dias, você receberá um link para agendar sua prova de nivelamento, essa
            prova é fundamental para garantir que estejamos todos na mesma página, e você receberá esse link no seu e-mail
            ou whatsapp informado no formulário anterior.
          </p>
          <br />
          <p>
            <strong>⏺️ 3. Entrevista de Admissão <br /></strong>
            Uma vez feita a prova e tendo conseguido nota suficiente para provar que os conceitos básicos sobre
            informática estão semeados, você receberá outro link para agendar uma entrevista presencial de admissão.
            <br />
            A entrevista será uma etapada tranquila e menos formal do que parece, o objetivo é conhecer você e entender
            melhor se a Arca tem o que é necessário para te ajudar.
          </p>
          <br />
          <p>
            <strong>❕ Observações importantes <br /></strong>
            Pontualidade e compromisso são importantes e contam muito na admissão à Arca. Sempre que marcar uma data e horário, fique atento a elas, pois estaremos esperando você atenciosamente.
            <br />
            Faltas e atrasos maiores que o nível de tolerância são motivos de desclassificação do processo de Admissão à Arca.
            <br />
            Somos um grupo de pessoas dedicas a oferecer educação transformadora de maneira acessível, e no caso do Introdução à Programação, de maneira gratuita. Considere e trata sua vaga como o privilégio que é 😁.
          </p>

          <br />
          <h4>Não perca nenhuma novidade sobre seu processo seletivo, <a href="https://discord.gg/UG2vvuhK">entre no nosso Discord.</a></h4>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <img :src="require('@/assets/icone_white.svg')" style="right:10px; bottom:0; position: absolute; width: 150px;">
</template>

<style scoped>
.about-us {
  font-family: 'Inconsolata';
}

.about-us p {
  font-size: 18px;
  width: 50%;
}

.title {
  font-size: 43px;
  width: 40%;
  line-height: 1;
  font-weight: 900;
  font-family: 'Inconsolata';
}

@media only screen and (max-width: 767px) {
  .title {
    width: 100%;
  }

  .about-us p {
    width: 100%;
  }
}
</style>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  data() {
    return {
      formSent: false,
      options: {
        showSocials: false
      },
      interestedPerson: {
        name: "",
        email: "",
        phone: ""
      }
    }
  },
  methods: {
    submitInterest: function () {
      // eslint-disable-next-line
      Parse.serverURL = 'https://parseapi.back4app.com';
      // eslint-disable-next-line
      Parse.initialize('7OxDxIzrwJGv302TXdMgrBoPycLk3hROrp8k92fr', 'TZV6mdch4gy9rLZBGl9vfOZ6gU4pkDBYpO4NAmUn', '4wcY02nIt4GnGQUtwbomJf4aXKky8ZYfoYcBNP13');

      (async () => {
        // eslint-disable-next-line
        const myNewObject = new Parse.Object('interested_people');
        myNewObject.set('nome', this.interestedPerson.name);
        myNewObject.set('email', this.interestedPerson.email);
        myNewObject.set('telefone', this.interestedPerson.phone);
        try {
          const result = await myNewObject.save();
          // Access the Parse Object attributes using the .GET method
          this.$swal('Sucesso!',
            'Agradecemos seu interesse. Manteremos contato.',
            'success');
          console.log('interested_people created', result);
          this.formSent = true;
        } catch (error) {
          this.$swal('Um erro aconteceu',
            'Por favor verifique o preenchimento de todos os campos e tente novamente.',
            'error');
          console.error('Error while creating interested_people: ', error);
        }
      })();

    }
  },
  components: {
  }
}
</script>
