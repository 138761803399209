<template>
  <v-app>
    <div class="bar"></div>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<style >
* {
  font-family: 'Inconsolata', monospace;
  font-family: 'Judson', serif;
  font-family: 'Open Sans', sans-serif;

}

.bar{
  height: 10px;
  background: #001866;
}

</style>
<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>
